import Vue from 'vue'
import App from './App.vue'
import vuescroll from 'vue-scroll'
import VueScrollStop from 'vue-scroll-stop'

//import Vuetify from 'vuetify'
import BootstrapVue from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import vueSmoothScroll from 'vue2-smooth-scroll'
import VueAnimate from 'vue-animate-scroll'
 

//import 'vuetify/dist/vuetify.min.css'
//import 'vuetify/src/stylus/main'
//Vue.use(Vuetify)
Vue.use(VueAnimate)
Vue.use(vuescroll)
Vue.use(VueScrollStop)
Vue.use(BootstrapVue)
Vue.use(vueSmoothScroll)


new Vue({
  el: '#app',
  render: h => h(App),
})
