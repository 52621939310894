<template>

<div>
<section class="page-section" id="portfolio">
<div class="container-fluid">
  <div class="mt-2"><h1>< Projects  ></h1></div>
  <div class=""><h2>< h2 >My Projects </ h2 ></h2></div>
 </div>
 <div class=""><h3>< h3 >Webs </ h3 ></h3></div>



<div class="row">

  <div class="column">
     <div class="content">
      <img class="imgPro" src="../assets/img/portfolio/spam1.png" alt="Proyecto Spam" style="width:100%">
      <h3>Spam Website</h3>
      <hr>
        <div class="row d-flex justify-content-center">
          <a href="https://github.com/iamcodex96/Dawnimals">
          <span class="fa fa-github fa-3x"></span>       
          </a>
           <a href="http://www.abp-politecnics.com/2019/daw/projecte02/dw04/">
          <span class="fa fa-globe fa-3x"></span>       
          </a>         
        </div>   
    </div>   
  </div>

  <div class="column">
    <div class="content">
    <img class="imgPro" src="../assets/img/portfolio/spambackend.png" alt="Lights" style="width:100%">
      <h3>Spam Backend</h3>
       <hr>
        <div class="row d-flex justify-content-center">
      <a href="https://github.com/iamcodex96/Dawnimals">
      <span class="fa fa-github fa-3x"></span>
      </a>
        <a href="http://www.abp-politecnics.com/2019/daw/projecte02/dw04/">
          <span class="fa fa-globe fa-3x"></span>       
          </a>
      </div>
    </div>
  </div>
  <div class="column">
    <div class="content">
    <img class="imgPro" src="../assets/img/portfolio/neverForget.png" alt="Nature" style="width:100%">
      <h3>Never Forget</h3>
       <hr>
        <div class="row d-flex justify-content-center">
       <a href="https://github.com/FrancescRetamal/Never_Forget">
      <span class="fa fa-github fa-3x"></span>
      </a>
        <a href="http://daw2a03.abp-politecnics.com/Never_Forget/views/">
          <span class="fa fa-globe fa-3x"></span>       
          </a>
      </div>
    </div>
  </div>
  <div class="column">
    <div class="content">
    <img class="imgPro" src="../assets/img/portfolio/friend.png" alt="Mountains" style="width:100%">
      <h3>Friend Finder Web</h3>
       <hr>
        <div class="row d-flex justify-content-center">
     <a href="https://github.com/EstherGaldeano/FriendFinderAngular">
      <span class="fa fa-github fa-3x"></span>
      </a>
      </div>
    </div>
  </div>
</div>

<div class=""><h3>< h3 >Apps </ h3 ></h3></div>
<div class="row d-flex justify-content-center">

  

   <div class="column2">
    <div class="content">
    <img class="imgPro" src="../assets/img/portfolio/todo1.png" alt="" style="width:100%">
      <h3 class="text-center">To do App</h3>
       <hr>
        <div class="row d-flex justify-content-center">

      <span class="fa fa-github fa-3x"></span>
      </a>
      </div>
    </div>
  </div>

 <div class="column2">
    <div class="content">
    <img class="imgPro" src="../assets/img/portfolio/gameSearch.png" alt="Mountains" style="width:100%">
      <h3>Game Search Ionic App</h3>
       <hr>
        <div class="row d-flex justify-content-center">
    <a href="https://github.com/EstherGaldeano/VideoGameSearch-Ionic">
      <span class="fa fa-github fa-3x"></span>
      </a>
      </div>
    </div>
  </div>

<div class="column2">
    <div class="content">
    <img class="imgPro" src="../assets/img/portfolio/friendFinderApp.png" alt="Mountains" style="width:100%">
      <h3 class="text-center">Friend Finder App</h3>
       <hr>
        <div class="row d-flex justify-content-center">
      <span class="fa fa-heart fa-3x"></span>
      </a>
      </div>
    </div>
  </div>
</div>
<div class=""><h3>< h3 >Games </ h3 ></h3></div>
<div class="row d-flex justify-content-center">
   <div class="column3">
    <div class="content">
    <img class="imgPro" src="../assets/img/portfolio/geek.png" alt="Mountains" style="width:100%">
      <h3>Geek Disaster</h3> 
       <hr>
        <div class="row d-flex justify-content-center">
    <a href="https://github.com/EstherGaldeano/GDisaster">
      <span class="fa fa-github fa-3x"></span>
      </a>
      </div>
    </div>
  </div>

   <div class="column3">
    <div class="content">
    <img class="imgPro"src="../assets/img/portfolio/spaceInvaders.png" alt="Mountains" style="width:100%">
      <h3>Space Invaders</h3>
       <hr>
        <div class="row d-flex justify-content-center">
    <a href="https://github.com/EstherGaldeano/Android-Space-Invaders">
      <span class="fa fa-github fa-3x"></span>
      </a>
      </div>
    </div>
  </div>

   <div class="column3">
    <div class="content">
    <img class="imgPro"src="../assets/img/portfolio/neverGame3.png" alt="Mountains" style="width:100%">
      <h3>Never Forget Brick Breaker</h3>
      <hr>
       <div class="row d-flex justify-content-center">
    <a href="https://github.com/FrancescRetamal/Never_Forget/tree/master/scripts/games/game3">
      <span class="fa fa-github fa-3x"></span>
      </a>
        <a href="http://daw2a03.abp-politecnics.com/Never_Forget/views/games/game3.php">
          <span class="fa fa-globe fa-3x"></span>       
          </a>
      </div>
    </div>
  </div>
<!-- END GRID -->
</div>

<div class="mt-5"><h1></ Projects  ></h1></div>
 


  </section>
</div>


</template>

<script>


export default{


    data() {
        return {
         
         
        }
    }
}

</script>

<style>


.fa-github{
  color:purple;
  margin-right:10px;
}

.fa-heart{
  color:purple;
  margin-right:10px;
}

.fa-globe{
 color:#554C55;
}

.fa-globe:hover {
  transform: scale(1.1);
}

.fa-github:hover {
  transform: scale(1.1);
}


.content h3{
  color:#554C55!important;
  margin-left:0;
  text-align: center;
  
}

.imgPro{
 border-radius: 5px;
 margin-bottom:10px;
}
.row {
  margin: 8px -16px;
}

/* Add padding BETWEEN each column */
.row,
.row > .column {
  padding: 8px;
}

.row,
.row > .column2 {
  padding: 8px;
}

.row,
.row > .column3 {
  padding: 8px;
}
/* Create four equal columns that floats next to each other */
.column {
  float: left;
  width: 25%;
}

.column2 {
  float: left;
  width: 30%;
}

.column3 {
  float: left;
  width: 30%;
}
/* Clear floats after rows */ 
.row:after {
  content: "";
  display: table;
  clear: both;
}

/* Content */
.content {
  background-color: white;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0px 0px 10px 0px #000000;
}

/* Responsive layout - makes a two column-layout instead of four columns */
@media screen and (max-width: 900px) {
  .column {
    width: 50%;
  }

  .column2{
    width: 50%
  }
   .column3{
    width: 50%
  }
}

/* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {
  .column {
    width: 100%;
  }
  .column2{
    width: 100%
  }
  .column3{
  width: 100%
  }
}


</style>



