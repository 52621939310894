
<template>

    <div class="container-fluid todo">
  
    <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Open+Sans:300" type="text/css" />

    <transition name="fade">
    <div v-show="elementVisible" v-scroll-stop  class="container-fluid fondo"><app-loading> </app-loading></div>
    </transition>
    
    <div><app-navbar> </app-navbar></div>

    <div><app-menu> </app-menu></div>

    <div  class="container-fluid landing1"><app-about> </app-about></div>

    <div  class="container-fluid landing2"><app-knowledge> </app-knowledge></div> 

    <div  class="container-fluid landing3"><app-tech> </app-tech></div>

    <div  class="container-fluid landing4"><app-contact> </app-contact></div>

    </div>

</template>


<script>

  import Knowledge from './components/Knowledge.vue';
  import Navbar from './components/Navbar.vue';
  import Tech from './components/Tech.vue';
  import AboutMe from './components/AboutMe.vue';
  import Footer from './components/Footer.vue';
  import Contact from './components/Contacto.vue';
  import Loading from './components/Loading.vue';


  export default{

    components:{
      'app-loading': Loading,
      'app-knowledge': Knowledge,
      'app-navbar': Navbar,
      'app-tech': Tech,
      'app-about': AboutMe,
      'app-contact': Contact,
      'app-footer': Footer
    },

    data() {
        return {
            elementVisible: true,
         
        }
    },

    created() {
     setTimeout(() => this.elementVisible = false, 3000)
   
    }
  }

</script>

<style>

@import url('https://fonts.googleapis.com/css?family=Delius&display=swap');
@import url('https://fonts.googleapis.com/css?family=Homemade+Apple&display=swap');
@import url('https://fonts.googleapis.com/css?family=La+Belle+Aurore&display=swap');


 body, html {
  background-color:#FCF7FC;
  -webkit-user-select: none; /* Safari 3.1+ */
  -moz-user-select: none; /* Firefox 2+ */
  -ms-user-select: none; /* IE 10+ */
  user-select: none; /* Standard syntax */
  overflow-x:hidden;
}


.fade-enter-active, .fade-leave-active {
  transition: opacity .3s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}


.imgFondo{
  width:170px;

}

.fondo{
  padding:0px!important;
}

.todo{
  padding:0px!important;
}

.landing4{
  padding:0px!important;
}

@media only screen and (max-width : 600px) {

  h1, h2, h3, h4, p{
  font-size:1.5rem!important;

  }

}

@media only screen and (max-width : 400px) {

  h1, h2, h3, h4, p{
  font-size:1.0rem!important;

  }

}



h1, h2, h3, h4, p{
font-family: 'La Belle Aurore', cursive;
color:purple;
margin-bottom:20px;

}

h3{
  margin-top:25px;
}

p{
  color:#554C55!important;
  font-size:18pt;
}

h1{
  margin-left:00px;
}
h2{
  margin-left:30px;
}

h3{
  margin-left: 70px;
}

h4{
  margin-left:120px;
}



.footer{
  width:100%;
  margin-top: 50px;
}


</style>
