exports = module.exports = require("../node_modules/css-loader/lib/css-base.js")(false);
// imports
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Delius&display=swap);", ""]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Homemade+Apple&display=swap);", ""]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=La+Belle+Aurore&display=swap);", ""]);

// module
exports.push([module.id, "\nbody, html {\n  background-color:#FCF7FC;\n  -webkit-user-select: none; /* Safari 3.1+ */\n  -moz-user-select: none; /* Firefox 2+ */\n  -ms-user-select: none; /* IE 10+ */\n  user-select: none; /* Standard syntax */\n  overflow-x:hidden;\n}\n.fade-enter-active, .fade-leave-active {\n  transition: opacity .3s;\n}\n.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {\n  opacity: 0;\n}\n.imgFondo{\n  width:170px;\n}\n.fondo{\n  padding:0px!important;\n}\n.todo{\n  padding:0px!important;\n}\n.landing4{\n  padding:0px!important;\n}\n@media only screen and (max-width : 600px) {\nh1, h2, h3, h4, p{\n  font-size:1.5rem!important;\n}\n}\n@media only screen and (max-width : 400px) {\nh1, h2, h3, h4, p{\n  font-size:1.0rem!important;\n}\n}\nh1, h2, h3, h4, p{\nfont-family: 'La Belle Aurore', cursive;\ncolor:purple;\nmargin-bottom:20px;\n}\nh3{\n  margin-top:25px;\n}\np{\n  color:#554C55!important;\n  font-size:18pt;\n}\nh1{\n  margin-left:00px;\n}\nh2{\n  margin-left:30px;\n}\nh3{\n  margin-left: 70px;\n}\nh4{\n  margin-left:120px;\n}\n.footer{\n  width:100%;\n  margin-top: 50px;\n}\n\n\n", ""]);

// exports
