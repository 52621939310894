<template>

<div>
    <section class="page-section" id="skills">
       <div class="mt-2"><h1>< Skills ></h1></div>
      <div class="container-fluid">
     
          <div class=""><h2>< h2 >My Skills </ h2></h2></div>

<div class="container-fluid box box2">
  <div class="row">

    <div class="col-sm text-center box2">
      <h2>Backlog</h2>
      <img class="postit" v-bind:data-popular="popular && popular.polymer" src="../assets/img/postits/ToDo/polymerPostit.svg" alt="polymer">
      <img class="postit" v-bind:data-popular="popular && popular.python" src="../assets/img/postits/ToDo/pythonPostit.svg" alt="python">
      <img class="postit" v-bind:data-popular="popular && popular.ruby" src="../assets/img/postits/ToDo/railsPostit.svg" alt="ruby">
      <img class="imgNeko"src="../assets/img/cat_.png"  alt="">
    </div>

    <div class="col-sm text-center box2">
        <h2>Doing</h2>
        <img class="postit" v-bind:data-popular="popular && popular.angular" src="../assets/img/postits/Doing/AngularPostit.svg" alt="angular">
        <img class="postit" v-bind:data-popular="popular && popular.ionic" src="../assets/img/postits/Doing/ionicPostit.svg" alt="ionic">
        <img class="postit" v-bind:data-popular="popular && popular.csharp" src="../assets/img/postits/Doing//CSharpPostit.svg" alt="c sharp">
        <img class="postit" v-bind:data-popular="popular && popular.php" src="../assets/img/postits/Doing/phpPostit.svg" alt="php">
        <img class="postit" v-bind:data-popular="popular && popular.laravel"src="../assets/img/postits/Doing/laravelPostit.svg" alt="laravel">      
        <img class="postit" v-bind:data-popular="popular && popular.vue"src="../assets/img/postits/Doing/vuePostit.svg" alt="vue">

    </div>

    <div class="col-sm text-center">
       <h2>Done</h2>
        <img class="postit" v-bind:data-popular="popular && popular.android" src="../assets/img/postits/Done/AndroidPostit.svg" alt=" android">
        <img class="postit" v-bind:data-popular="popular && popular.bootstrap"src="../assets/img/postits/Done/BootstrapPostit.svg" alt="bootstrap">
        <img class="postit" v-bind:data-popular="popular && popular.c"src="../assets/img/postits/Done/CPostit.svg" alt="c">
        <img class="postit" v-bind:data-popular="popular && popular.css" src="../assets/img/postits/Done/cssPostit.svg" alt="css">
        <img class="postit" v-bind:data-popular="popular && popular.html" src="../assets/img/postits/Done/htmlPostit.svg" alt="html">
        <img class="postit" v-bind:data-popular="popular && popular.java" src="../assets/img/postits/Done/javaPostit.svg" alt="java">
        <img class="postit" v-bind:data-popular="popular && popular.javascript" src="../assets/img/postits/Done/javascriptPostit.svg" alt="javascript">
        <img class="postit" v-bind:data-popular="popular && popular.jquery"src="../assets/img/postits/Done/jQueryPostit.svg" alt="jquery">
        <img class="postit" v-bind:data-popular="popular && popular.mysql"src="../assets/img/postits/Done/mysqlPostit.svg" alt="mysql">
        <img class="postit" v-bind:data-popular="popular && popular.unity"src="../assets/img/postits/Done/unityPostit.svg" alt="unity">

    </div>
   
  </div>
  
</div>

    </div>
 
  </section>
   <div class="mt-2"><h1></ Skills ></h1></div>
</div>

</template>

<script>

import axios from 'axios';

  export default {
    data() {
      return {
      popular: null,
      }
    },

    mounted () {
    axios
      .get('https://github-trending-api.now.sh/languages')
      .then(response => {

        this.popular = {};

        for(let pop of response.data.popular) {
          this.popular[pop.urlParam] = true;
        }      

        return this.popular;
      })
    }
  }
</script>

<style>


img.postit[data-popular]{
  border-bottom: 2px solid purple;
  border-radius:6% 172% 21% 52%/68% 0% 7% 7%;
  padding-bottom: 10px;
}

.box2 h2{
  margin-top:20px;
}
.imgNeko{
  max-width:60%;
  margin-top:100px;
}


.postit{
width:auto;
max-width:25%;
min-width:15%;

}


.postit:hover{
  -webkit-transition: -webkit-transform .2s ease-in-out;
  transition: transform .2s ease-in-out;
  -webkit-transform: rotate(2deg);
  transform: rotate(2deg);
}


.box2{
border-right: solid black;
border-color: purple;
width:auto;
border-width: 3px 3px 5px 5px;
border-radius:4% 95% 6% 95%/95% 4% 92% 5%;
transform: rotate(-2deg);

}

.box{
  margin:25px;
  width:auto;
  height:auto;
  background-color:#FCF7FC;
  border: solid black;
  border-color: purple;
  width:auto;
  border-width: 3px 3px 5px 5px;
  border-radius:4% 95% 6% 95%/95% 4% 92% 5%;
  transform: rotate(-1deg);
 
}

.boxGit{
  width:30%;
  height:auto;
   background-color:#FCF7FC;
  transform: rotate(-1deg);
 
}


</style>
