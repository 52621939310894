<template>

<div class="fondoInicio">
  <!--<div>{{info}}</div>-->
    <parallax class="prueba">

       <div class="container-fluid text-center secciones">
              <img class="logoRetse"src="../assets/img/logo.png"  alt="logo retse dev">
              <b-row class="mt-5">
             
              <div class="col-lg-12 col-md-12 webSection"><h1><a href="#AboutMe" v-smooth-scroll>< About Me  ></a> </h1></div>    
              <div class="col-lg-12 col-md-12 webSection"><h1><a href="#portfolio" v-smooth-scroll> < Projects  ></a></h1></div>
              <div class="col-lg-12 col-md-12 webSection"><h1><a href="#skills" v-smooth-scroll>< Skills  ></a></h1></div>
              <div class="col-lg-12 col-md-12 webSection"><h1><a href="#lab_social_icon_footer" v-smooth-scroll>< Contact  ></a></h1></div> 
              </b-row>

          </div>

        <img class="imgFondo "src="../assets/img/Landing/desktop-web.jpg"  alt="">

    </parallax>
         
</div>

</template>

<script>

  import axios from 'axios';
  import Parallax from 'vue-parallaxy'
  export default {
    components: {
      Parallax
    },
    data () {
        return {
          info: null
        }
      },
    mounted () {
      axios
        .get('https://api.openweathermap.org/data/2.5/weather?q=Barcelona&APPID=b9282cb071f1fb9170d2931c8c825a62')
        .then(response => {
        
        this.info = {};
  
        for(let inf of response.data.weather) {
          this.info[inf.description] = "Barcelona";
        }      

        return this.info;
      })
    }
  }

</script>

<style>

.logoRetse{
  width:150px;
}
.Masthead {
  z-index: 0!important;
}

.fondoInicio {
  height:100vh;

}
.secciones a, .secciones a:hover {
  color: inherit;
  text-decoration: inherit;
}

.text-center.secciones {
  position: absolute;
  top:5%;
}

@media screen and (max-width: 600px) {
  .Masthead__image.is-parallax > img {
 
  
  }

  .fondoInicio {
    height: 70vh!important;
    margin:0px;
}
.text-center.secciones {
  position: absolute;
  top:4%;
}

.logoRetse{
  width:100px;
}
}

</style>
